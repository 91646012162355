.aboutme-handle {
    height: 1.1em;
    cursor: pointer;
    background: var(--clr-bg2);
    color: var(--clr-bg);
    font-weight: 500;
    padding: .3em .5em;
    border-radius: .3em .3em 0 0;
    transition: 250ms all ease-in-out;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.aboutme-handle:hover {
    box-shadow: 0 0 .3em 0 #0000008c;
    background: var(--clr-bg2-dark);
}

.aboutme-overlay {
    position: fixed;
    width: 100%;
    height: 100%;    
}

.aboutme-body {
    height: 60vh;
    bottom: 0;
    transform: translateY(60vh);
    position: fixed;
    width: 100%;
    max-width: 600px;
    background: white;
    transition: 500ms all ease-in-out;
    border-radius: 1.1em 1.1em 0 0;
    align-items: center;
}

.aboutme-content {
    height: 60vh;
    bottom: 0;
    position: fixed;
    width: 100%;
    max-width: 600px;
    align-items: center;
    overflow: scroll;    
    background: none;
}

.aboutme-body p {
    font-size: .8em;
    max-width: 90%;
    text-align: start;
    margin-bottom: .5em;
    
}

.aboutme-body.visible {
    transform: translateY(0);
}



.filler {
    flex: 1;
}


.social-buttons {
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    margin-bottom: 1em;
}

.social-buttons > a {
    width: 32%;
    height: 3.7em;
    border-radius: .5em;
    cursor: pointer;
    transition: 200ms all ease-in-out;
    display: flex;
    flex-direction: column;
    text-decoration: none;
}

.social-buttons > a:hover {   
    opacity: .9;
}

.social-buttons > a:hover img {    
    padding: 1em 0;
}


.social-buttons span {
    color: var(--clr-bg);
    margin-top: .2em;
   
}

.social-buttons img {
    width: 30%;
    height: auto;
    filter: invert();
    margin: auto;
    transition: 200ms all ease-in-out;

}

.social-buttons .email {
    background: var(--clr-bg2-darker);
}

.social-buttons .linkedin {
    background: #0277b5;
}

.social-buttons .github {
    background: #24292e;
}


.aboutme-body::before {
    content: '';
    box-shadow: rgba(0, 0, 0, 0.56) 0 .3em 2em;
    opacity: 0;    
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 1.1em 1.1em 0 0;
    transition: 500ms opacity ease-in-out;

}

.aboutme-body.visible::before {
    opacity: 1;
}