
.timer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}


.time-container {
  font-size: 4em;
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
}

.time-container > div {
  width: 1.4em;
}

.time-container > .h::after {
  content: "Hours";
  font-size: .3em;
  margin-top: -.5em;
}
.time-container > .m::after {
  content: "Minutes";
  font-size: .3em;
  margin-top: -.5em;
}
.time-container > .s::after {
  content: "Seconds";
  font-size: .3em;
  margin-top: -.5em;
}

.time-container .selected {
  background: var(--clr-bg2-darker);
  border-radius: .1em;
  color: var(--clr-bg);
}

.number-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  background: var(--clr-bg2);
  padding: .7em 0 .1em;
}

.number-buttons * {
  background: var(--clr-bg);
  color: var(--clr-bg2);
  font-size: 1.8em;
  font-weight: bold;
  width: 17%;
  height: 1.8em;
  margin-bottom: .3em;  
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding:0; 
}

.back-button {
  position: absolute;
  top: 0;
  left: 0;
  padding:0;
  height: 2em;
  width: 2em;
  border-radius: 0 0 .3em 0;
  border:none;
}

.back-button::before {
  content: "";
  display: inline-block;
  background: url(../../assets/close.svg) 50% 50% no-repeat;
  filter: invert();
  width: 1em;
  background-size: contain;
  height: 100%;
}

.security-button {
  position: absolute;
  top: 0;
  right: 0;
  padding:0;
  height: 2em;
  width: 2em;
  border-radius: 0 0 0 .3em;
  border:none;
}

.security-button.unlock {
  background: var(--clr-text);
}

.security-button.lock::before {
  content: "";
  display: inline-block;
  background: url(../../assets/unlock.svg) 50% 50% no-repeat;
  filter: invert();
  width: 1em;
  background-size: contain;
  height: 100%;
}

.security-button.unlock::before {
  content: "";
  background: red;
  display: inline-block;
  background: url(../../assets/lock.svg) 50% 50% no-repeat;
  filter: invert();
  width: 1em;
  background-size: contain;
  height: 100%;
}

.locked-alert {
  background: #e84118;
  color :white;
  font-weight: 400;
  height: 3em;
  line-height: 3em;
  position: absolute;
  padding: 0 .3em;
  border-radius: 0 0 0 .5em;
  right: 0;
  transform: translateX(5em);
  cursor: pointer;
}

.locked-alert.visible {
  animation: bump 1s normal ease-in-out; 
}

@keyframes bump {
  0% {
    transform: translateX(5em);
  }

  25% {
    transform: translateX(0);
  }

  37% {
    transform: scale(1.2) ;
  }

  50% {
    transform: scale(1) ;
  }

  62% {
    transform: scale(1.2) ;
  }

  75% {
    transform:  scale(1) ;
  }

  100% {
    transform: translateX(5em);
  } 
}

.big-button {
  text-transform: uppercase;
  letter-spacing: .1em;
  font-weight: 500;
  width: 100%;
  border-radius: 0;
  height: 2em;
  font-size: 1.5em;
}

.big-button.start {
  margin-top: 1em;
}

.modalTitle {
 margin: .3em 0 .5em;
}

.modalInput {
  display: inline-block;
  font-size: 1em;
  border: none;

  margin-left: 20%;
  margin-bottom: 1em;
  width: 60%;
  
  border-bottom: .01em solid var(--clr-text2);
  outline: none;
}

.modalInput::placeholder {
  color: unset !important;
}

.modalButtons {
  flex-direction: row;
}

.modalButtons div {
  cursor: pointer;
  flex: 1;
  padding: .3em 0;
  transition: 200ms all;
}

.modalButtons div:hover {
  opacity: .8;
  background: #f0f0f0;
}