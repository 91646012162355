:root {
  --clr-bg: #fff;
  --clr-bg2: #f0be77;
  --clr-bg2-dark: #f0aa48;
  --clr-bg2-darker: #de932a;
  --clr-text: #0c0c0c;
  --clr-text2: #919191; 
}

::selection {
  background-color: transparent;
}

.App {
  text-align: center;
  cursor: default;
  background: var(--clr-bg2);
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}

.App-container {
  box-shadow: rgba(0, 0, 0, 0.56) 0 .3em 2em;
  position: relative;
  background-color: var(--clr-bg);
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 4.5vw;
  color: var(--clr-text);
  width: 100%;
  max-width: 600px;
  margin: auto;
  overflow-x: hidden;
  overflow-y: scroll;
}

@media (min-width: 600px) {
  .App-container {
    font-size: 27px !important;
  }
}

button {
  padding: 0 .75em;
  height: 1.4em;
  line-height: 1em;
  border-radius: .3em;
  color: var(--clr-bg);
  margin-bottom: .3em;
  font-size: 1.4em;
  outline: none;
  opacity: 1;
  transition: 200ms all;
  cursor: pointer;
  background: var(--clr-bg2);
  border: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

button:hover {
  background: var(--clr-bg2-dark);
}
button:active {
  background: var(--clr-bg2-darker);
}

.disabled {
  background: darkgray !important;
}


div {
  display: flex;
  flex-direction: column;
}

.landing {
  width: 100%;
  flex:1;
  align-items: center;
}

/* LANDING TITLE */ 
.title {
  width: 80%;
  margin-top: 1.4em;
}

.title-logo {
  height: 2.5em;
  width: 100%;
  background: url(assets/logo-full.svg) no-repeat center;
  background-size: contain; 
}

.title-text {
  font-weight: 300;
  font-size: 1.1em;
  margin-top: 0.45em;
  text-transform: uppercase;

}

/* LANDING CONTENT */
.landing-content {
  flex:1;
  justify-content: center;
  padding: 0 0.45em;
  
}

.landing-content h2 {
  font-weight: 300;
  margin-bottom: 0.45em;
}

.landing-content h4 {
  margin:0;
  font-weight: 300;
}

/* RECENT TIMERS */
.recent-timers {
  display: block;
  width: 95%;
  text-align: left;
  margin-bottom: 0.9em;
}

.recent-timers label {
  display: inline-block;
  font-size: .7em;
  font-weight: 500;
  letter-spacing: .1em;
  width: 100%;
  text-transform: uppercase;
}

.recent-timers button {  
  margin: 0.2em 0.4em 0 0;
  padding: 0.2em 0.4em;
  height: unset;
  font-size: .8em;
}

/* TIMER NAME INPUT */
.name-input {
  display: block;
  width: 95%;
  text-align: left;
  margin-bottom: 3.2em;
}

.name-input .label {
  display: block;
  float: left;
  font-size: .7em;
  font-weight: 500;
  letter-spacing: .1em;
  width: 100%;
  text-transform: uppercase;
}

.name-input .prefix {
  font-size: 1em;
  display: inline-block;
  width: 7em;
}

.name-input .input {
  display: inline-block;
  font-size: 1em;
  width: calc(95% - 7em);
  color: var(--clr-bg2) !important;


  border: none;
  border-bottom: .01em solid var(--clr-text2);
  outline: none;
}
::placeholder {
  color: var(--clr-bg2) !important;
}

.name-input .btn-select {
  float: right;
  margin-top: .4em;
  margin-right: 3%;
}

.name-input .btn-select::before {
  content: "";
  display: inline-block;
  background: url(./assets/arrow-right.svg) 50% 50% no-repeat;
  width: 2em;
  background-size: contain;
  height: 100%;
}